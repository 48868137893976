<!--
 * @Author: 肖槿
 * @Date: 2020-06-12 15:49:00
 * @Description: 小店管理
 * @LastEditors: 肖槿
 * @LastEditTime: 2020-06-30 14:55:48
 * @FilePath: \s2b-admin\src\pages\admin\shop\shop-management.vue
-->
<template>
  <div id="shop-management">
    <shop-management-search @success="searchHandler" :loading="listLoading" />
    <div style="margin-top: 10px;">
      <AButton
        type="primary"
        href="https://kuran-oss.oss-cn-hangzhou.aliyuncs.com/kuran-test/Ybe4bDZR8ZKxjPA6x7.docx"
        download="小店授权帮助文档"
        >小店授权帮助文档</AButton
      >
    </div>
    <a-table
      v-auth="'manage_small_shop_manage_page'"
      :columns="MerchantTable"
      :dataSource="list"
      :loading="listLoading"
      :locale="{ emptyText: '暂无信息' }"
      :pagination="tablePage"
      @change="paginationHandler"
      :scroll="{ x: scrollWidth ,y: tableScrollY }"
      style="margin-top: 12px;"
    >
      <template slot="category" slot-scope="row">{{ row.shop_type | shopMargin(Brand) }}</template>
      <template slot="type" slot-scope="row">{{ row.shop_status | shopMargin(Enabled) }}</template>
      <template slot="scope" slot-scope="row">{{ row.biz_scopes }}</template>
      <template slot="action" slot-scope="row">
        <a-button
          v-auth="'manage_small_shop_manage_edit'"
          size="small"
          @click="openShopEditDrawer(row)"
          style="margin-right:10px;"
          >修改</a-button
        >
        <a-popconfirm
          :title="`确定${row.shop_status === 0 ? '启用' : '禁用'}该小店吗？`"
          ok-text="是"
          cancel-text="否"
          @confirm="confirmMargin(row)"
        >
          <a-button size="small">{{ row.shop_status === 0 ? '启用' : '禁用' }}</a-button>
        </a-popconfirm>
      </template>
    </a-table>
    <a-drawer
      title="小店信息"
      placement="right"
      width="850"
      :visible="merchanDrawerVisiable"
      @close="handlemerchantInfoDrawerClose"
    >
      <div>
        <ShopDetail
          ref="shopDetail"
          :category="categoryOptions"
          @success="onCreateSuccess"
          @close="handlemerchantInfoDrawerClose"
        />
      </div>
    </a-drawer>
    <ShopEditDrawer
      :data="chekcedRow"
      :visible="shopEditDrawerVisiable"
      :category="categoryOptions"
      @success="onEditSuccess"
      :close="closeShopEditDrawer"
    />
  </div>
</template>

<script>
import { shopManagementSearch, shopDetail } from '@/components/admin/shop';
import { Brand, Enabled } from '@/const/shop';
import { getMerchantList } from '@/service/shop';
import { ObjectFilterEmpty, getCleanObjFromVue, shopFormatDateTime } from '@/utils/func';
import { EnableShop, DisableShop, GetCategory } from '@/service/shop2';
import ShopEditDrawer from './shop/edit.drawer.vue';

export default {
  name: 'ShopManagement',
  components: {
    shopManagementSearch,
    ShopDetail: shopDetail,
    ShopEditDrawer,
  },
  data() {
    const MerchantTable = [
      {
        title: '小店名称',
        dataIndex: 'name',
        ellipsis: true,
        width: 300,
        fixed: 'left',
      },
      {
        title: '小店ID',
        dataIndex: 'id',
        ellipsis: true,
        width: 80,
        fixed: 'left',
      },
      {
        title: '授权开始时间',
        dataIndex: 'start_time',
        ellipsis: true,
        width: 200,
        customRender: timestamp => shopFormatDateTime(timestamp),
      },
      {
        title: '小店类型',
        scopedSlots: { customRender: 'category' },
        width: 90,
      },
      {
        title: '小店状态',
        scopedSlots: { customRender: 'type' },
        width: 90,
      },
      {
        title: '经营范围',
        dataIndex: 'biz_scopes',
        ellipsis: true,
        width: 600,
        customRender: text =>
          this.getCategories(text)
            .map(el => el.name)
            .join(' / '),
      },
      {
        title: '联系电话',
        dataIndex: 'contact_mobile',
        width: 135,
      },
      {
        title: '操作',
        scopedSlots: { customRender: 'action' },
        width: 160,
        fixed: 'right',
      },
    ];

    return {
      tableScrollY: 600,
      listLoading: true,
      Brand,
      Enabled,
      queryData: {},
      MerchantTable,
      pagination: {
        page: 1,
        page_size: 10,
        total: 0,
      },
      list: [],
      merchanDrawerVisiable: false,
      detailFlag: false,
      // 小店编辑弹层
      shopEditDrawerVisiable: false,
      chekcedRow: {},
      category: [],
      categoryOptions: [],
      scrollWidth: MerchantTable.reduce((acc, cur) => acc + cur, 0),
    };
  },
  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 335;
  },
  computed: {
    tablePage() {
      return {
        current: this.pagination.page,
        total: this.pagination.total,
        pageSize: this.pagination.page_size,
        hideOnSinglePage: true,
      };
    },
  },
  methods: {
    confirmMargin(row) {
      this.setShopStatus(row);
    },
    paginationHandler({ current }) {
      this.pagination.page = current;
      this.getData();
    },
    searchHandler(data) {
      this.queryData = {};

      this.queryData = data;
      this.getData();
    },
    async getData() {
      this.listLoading = true;

      const _obj = {
        ...this.pagination,
        ...this.queryData,
      };

      const response = await getMerchantList(ObjectFilterEmpty(_obj));
      const { res } = response;

      this.listLoading = false;
      if (res !== null) {
        this.pagination.total = res.data.total || 0;
        this.list = res.data.rows || [];
      } else {
        // this.$notification.error({
        //   message: '服务器开小差了',
        // });
      }
    },
    handlemerchantInfoDrawerClose() {
      this.merchanDrawerVisiable = false;
    },
    /**
     * 创建出成功回调
     * @author Jerry <superzcj_001@163.com>
     * @since  2020-06-29 14:40:04
     */
    onCreateSuccess() {
      this.handlemerchantInfoDrawerClose();
      this.getData();
    },
    /**
     * 设置小店启用/禁用状态
     * @author Jerry <superzcj_001@163.com>
     * @since  2020-06-28 16:59:50
     */
    async setShopStatus(row) {
      if (row.shop_status === 0) {
        const { res } = await EnableShop(row.id);

        if (res !== null && res.error_code === 0) {
          this.$message.success('启用成功');
          this.getData();
        } else {
          this.$message.error('启用失败');
        }
      } else {
        const { res } = await DisableShop(row.id);

        if (res !== null && res.error_code === 0) {
          this.$message.success('禁用成功');
          this.getData();
        } else {
          this.$message.error('禁用失败');
        }
      }
    },
    /**
     * 显示修改小店弹层
     * @author Jerry <superzcj_001@163.com>
     * @since  2020-06-29 13:33:57
     */
    openShopEditDrawer(row) {
      this.shopEditDrawerVisiable = true;
      this.chekcedRow = getCleanObjFromVue(row);
    },
    /**
     * 关闭修改小店弹层
     * @author Jerry <superzcj_001@163.com>
     * @since  2020-06-29 13:34:09
     */
    closeShopEditDrawer() {
      this.shopEditDrawerVisiable = false;
    },
    /**
     * 修改成功回调
     * @author Jerry <superzcj_001@163.com>
     * @since  2020-06-29 14:39:14
     */
    onEditSuccess() {
      this.getData();
    },
    /**
     * 获取一级类目
     * @author Jerry <superzcj_001@163.com>
     * @since  2020-06-28 14:43:00
     */
    async getCategory() {
      const { res } = await GetCategory(0);

      if (res !== null) {
        if (res.error_code === 0) {
          this.category = res.data;

          this.categoryOptions = res.data.map(cate => ({
            label: cate.name,
            value: cate.id,
          }));
        }
      }
    },
    /**
     * 显示经营范围
     * @author Jerry <superzcj_001@163.com>
     * @since  2020-06-29 14:55:45
     */
    getCategories(biz_scopes) {
      const ids = biz_scopes.map(el => el.first_cat_id);
      return this.category.filter(cate => ids.includes(cate.id));
    },
  },
  mounted() {
    this.getData();
    this.getCategory();
  },
};
</script>

<style lang="less" scoped></style>

<template>
  <ADrawer title="修改小店信息" placement="right" width="850" :visible="visible" @close="close">
    <div>
      <AFormModel
        ref="shopForm"
        :model="shopData"
        :rules="rules"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 15 }"
        :form="form"
        @submit="handleFormSubmit"
      >
        <AFormModelItem label="小店名称：" prop="name" style="margin-bottom: 0">
          <span>{{ shopData.name }}</span>
        </AFormModelItem>
        <AFormModelItem label="小店ID：" prop="id" style="margin-bottom: 0">
          <span>{{ shopData.id }}</span>
        </AFormModelItem>
        <AFormModelItem label="授权开始时间：" prop="start_time" style="margin-bottom: 0">
          <span>{{ startTime }}</span>
        </AFormModelItem>
        <AFormModelItem label="联系电话：" prop="contact_mobile">
          <AInput
            v-model="shopData.contact_mobile"
            @keyup="onInputMobile"
            @input="onInputMobile"
            allowClear
            placeholder="请输入联系电话"
          />
        </AFormModelItem>
        <AFormModelItem label="小店类型：" prop="shop_type">
          <ASelect v-model="shopData.shop_type" placeholder="全部" allowClear style="width:190px;">
            <ASelectOption v-for="(item, k) in Brand" :key="k" :value="item.value">{{
              item.label
            }}</ASelectOption>
          </ASelect>
        </AFormModelItem>
        <AFormModelItem label="经营范围：" prop="first_cat_ids">
          <a-checkbox-group
            v-model="shopData.first_cat_ids"
            name="checkboxgroup"
            :options="category"
          >
            <span slot="label" slot-scope="{ name }">{{ name }}</span>
          </a-checkbox-group>
        </AFormModelItem>
        <AFormModelItem>
          <AButton type="primary" html-type="submit" :loading="loading" style="margin-right:20px;"
            >确定</AButton
          >
          <AButton :loading="loading" @click="formReset">取消</AButton>
        </AFormModelItem>
      </AFormModel>
    </div>
  </ADrawer>
</template>

<script>
import { Brand } from '@/const/shop';
import { EditShop } from '@/service/shop2';
import { DiffObject, shopFormatDateTime } from '@/utils/func';

export default {
  name: 'KrEditDrawer',
  data() {
    return {

      form: this.$form.createForm(this),
      loading: false,
      Brand,
      shopData: {
        name: '',
        contact_mobile: '',
        shop_type: '',
        app_key: '',
        app_secret: '',
        first_cat_ids: [],
      },
      shopDataBackup: {
        name: '',
        contact_mobile: '',
        shop_type: '',
        app_key: '',
        app_secret: '',
        first_cat_ids: [],
      },
      rules: {
        // name: [
        //   {
        //     required: true,
        //     message: '请填写小店名称',
        //     trigger: 'blur',
        //   },
        //   {
        //     max: 50,
        //     message: '小店名称最长为50个字符',
        //     trigger: 'blur',
        //   },
        // ],
        contact_mobile: [
          {
            required: true,
            message: '请填写联系电话',
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              if (/^\d{11}$/.test(value)) {
                callback();
              }

              callback(new Error('请填写正确的联系电话'));
            },
            trigger: 'blur',
          },
        ],
        shop_type: [
          {
            required: true,
            message: '请选择小店类型',
            trigger: 'blur',
          },
        ],
        first_cat_ids: [
          {
            required: true,
            message: '请选择经营范围',
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              if (value.length === 0) {
                callback(new Error('请选择经营范围'));
              }

              callback();
            },
            trigger: 'blur',
          },
        ],
        // app_key: [
        //   {
        //     required: true,
        //     message: '请填写 App key',
        //     trigger: 'blur',
        //   },
        // ],
        // app_secret: [
        //   {
        //     required: true,
        //     message: '请填写授权码',
        //     trigger: 'blur',
        //   },
        // ],
      },
    };
  },
  props: {
    data: {
      required: true,
    },
    visible: {
      type: Boolean,
    },
    close: {
      type: Function,
    },
    category: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    startTime() {
      const timestamp = this.shopData.start_time;
      return timestamp ? shopFormatDateTime(timestamp) : '';
    },
  },

  methods: {
    /**
     * @author Jerry <superzcj_001@163.com>
     * @since  2020-06-28 14:51:26
     */
    handleFormSubmit(event) {
      event.preventDefault();
      this.$refs.shopForm.validate(valid => {
        if (valid) {
          this.editShop();
        }
      });
    },
    /**
     * 保存小店
     * @author Jerry <superzcj_001@163.com>
     * @since  2020-06-28 15:44:04
     */
    async editShop() {
      const payload = this.getDiffPayload();

      const { res } = await EditShop(this.shopDataBackup.id, payload);

      if (res !== null) {
        if (res.error_code === 0) {
          this.$message.success('修改小店成功');
          this.$emit('success');
          this.close();
        } else {
          this.$message.error('修改小店失败');
        }
      } else {
        this.$message.error('修改小店失败');
      }
    },
    onInputMobile(event) {
      const value = event.target.value;

      const val = value.replace(/\D+/g, '');

      const result = /\d{0,11}/g.exec(val);

      if (result === null) {
        this.shopData.contact_mobile = '';
        event.target.value = '';
      } else {
        this.shopData.contact_mobile = result[0];
        event.target.value = result[0];
      }
    },
    formReset() {
      this.$refs.shopForm.resetFields();
      this.close();
    },
    /**
     * 将表单数据格式化
     * 主要是 first_cat_ids 的字符串化
     * 其它字段都是 string \ number 可以直接对比
     * @author Jerry <superzcj_001@163.com>
     * @since  2020-06-30 10:44:19
     */
    stringifyFrmData(data) {
      const { first_cat_ids, ...rest } = data;

      return {
        first_cat_ids: first_cat_ids.join(','),
        ...rest,
      };
    },
    /**
     * 比对数据, 返回有变化的部分
     * @author Jerry <superzcj_001@163.com>
     * @since  2020-06-30 10:44:08
     */
    diffFrmData() {
      const dataNow = this.stringifyFrmData(this.shopData);
      const dataBackup = this.stringifyFrmData(this.shopDataBackup);

      return DiffObject(dataBackup, dataNow);
    },
    /**
     * 获取仅包含变化数据的payload
     * @author Jerry <superzcj_001@163.com>
     * @since  2020-06-30 10:49:57
     */
    getDiffPayload() {
      return this.diffFrmData().reduce((acc, cur) => {
        return {
          ...acc,
          ...cur,
        };
      }, {});
    },
    /**
     * 初始化回填数据
     * @author Jerry <superzcj_001@163.com>
     * @since  2020-06-29 14:40:18
     */
    __initFrmData() {
      const {
        id,
        name,
        start_time,
        contact_mobile,
        shop_type,
        app_key,
        app_secret,
        shop_status,
        biz_scopes,
      } = this.data;

      this.shopData = {
        id,
        name,
        start_time,
        contact_mobile,
        shop_type,
        app_key,
        app_secret,
        shop_status,
        first_cat_ids: biz_scopes.map(el => el.first_cat_id),
      };

      this.shopDataBackup = {
        id,
        name,
        contact_mobile,
        shop_type,
        app_key,
        app_secret,
        shop_status,
        first_cat_ids: biz_scopes.map(el => el.first_cat_id),
      };
    },
  },
  watch: {
    visible(val, oldVal) {
      if (val && val !== oldVal) {
        this.__initFrmData();
      }
    },
  },
};
</script>
